<template>
  <div class="w-100">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-5">
            <div
              class="d-flex flex-column align-items-center w-100"
              style="gap: 16px"
            >
              <div class="w-100">
                <label
                  for="name"
                  class="d-flex justify-content-between align-items-center"
                >
                  <span class="text-uppercase"> Name </span>

                  <span class="text-danger"> * </span>
                </label>
                <input
                  type="text"
                  name=""
                  v-model.trim="emailTemplateDetail.name"
                  id="name"
                  class="quizell-form-control w-100 px-2"
                />
              </div>

              <div class="mt-3 emailTemplatesContainer w-100">
                <div class="row w-100" v-if="isEmailTemplateLoading">
                  <div
                    class="col-6 mb-3 mt-3 mx-auto"
                    v-for="item in 4"
                    :key="item"
                  >
                    <div class="singleEmailTemplateContainer">
                      <div
                        class="bg-preload"
                        style="height: 230px; width: 100%"
                      >
                        <div class="into-preload"></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-else>
                  <div class="row w-100">
                    <div
                      class="col-6 mb-3 mt-3 mx-auto"
                      v-for="(emailTemplate, index) in emailTemplates"
                      :key="index"
                    >
                      <div
                        class="singleEmailTemplateContainer cursor-pointer"
                        @click="selectTemplateID(emailTemplate.id)"
                        :class="
                          emailTemplate.id == emailTemplateDetail.selectedEmailTemplateId
                            ? 'selected'
                            : null
                        "
                      >
                        <div>
                          <LazyImage
                            :src="emailTemplate.image"
                            :key="emailTemplate.image"
                            style="
                              height: 250px;
                              width: 100%;
                              aspect-ratio: 16 /9;
                              object-fit: contain;
                            "
                            alt=""
                          />
                        </div>

                        <div class="mt-3 px-3">
                          <p class="templateName">{{ emailTemplate.name }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div class="mt-5 w-100">
                <div class="d-flex align-items-center justify-content-start">
                  <Button
                    class="btn"
                    :disabled="!isChooseTemplateValid"
                    style="
                      background: #4d1b7e;
                      color: #fff;
                      border-radius: 6px;
                      min-width: 119px;
                      font-size: 14px;
                    "
                    >Choose Template</Button
                  >
                </div>
              </div> -->
            </div>
          </div>
          <div class="col-md-7">
            <div class="d-flex h-100 w-100">
              <div class="card w-100">
                <div class="card-body h-100 w-100">
                  <div
                    v-if="!isEmailPreviewLoading && !emailPreviewData"
                    class="d-flex h-100 justify-content-center flex-column align-items-center"
                  >
                    <div class="icon">
                      <img
                        width="90"
                        height="90"
                        src="https://img.icons8.com/external-yogi-aprelliyanto-detailed-outline-yogi-aprelliyanto/90/external-drag-and-drop-website-development-yogi-aprelliyanto-detailed-outline-yogi-aprelliyanto.png"
                        alt="external-drag-and-drop-website-development-yogi-aprelliyanto-detailed-outline-yogi-aprelliyanto"
                      />
                    </div>
                    <div class="text-center">
                      <div class="" style="font-size: 16px; font-weight: 500">
                        Email Preview
                      </div>
                      <div class="" style="font-size: 12px">
                        Select an email template to preview
                      </div>
                    </div>
                  </div>

                  <div v-else class="h-100 w-100">
                    <div
                      class="d-flex h-100 justify-content-center flex-column align-items-center"
                      v-if="isEmailPreviewLoading"
                    >
                      <div class="icon" style="min-height: 100px">
                        <LoaderComp />
                      </div>
                      <div class="text-center mt-5">
                        <div class="" style="font-size: 16px; font-weight: 500">
                          Fetching Template Data
                        </div>
                        <div class="" style="font-size: 12px">
                          Hang tight! This may take a while.
                        </div>
                      </div>
                    </div>

                    <div v-else class="w-100">
                      <div class="htmlContainer">
                        <div class="w-100 ">
                          <div class="d-flex">
                            <div
                              @click="toggleView('desktop')"
                              class="toggleViewIcon d-flex justify-content-center align-items-center rounded-lg large mx-1"
                              :class="{
                                active: emailBrowserView === 'desktop',
                              }"
                            >
                              <svg
                                width="24"
                                height="22"
                                viewBox="0 0 24 22"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M23.9906 13.2895V2.05614C23.9906 0.93692 23.0606 0 21.9293 0H2.07075C0.939188 0 0.00937498 0.93692 0.00937498 2.05614V13.2895C0.00937498 13.3225 0 13.3561 0 13.391C0 13.4255 0.00937498 13.4592 0.00937498 13.4922V15.4586C0.0144375 16.5817 0.935063 17.4903 2.07075 17.4933H8.94244V19.0637C8.94637 19.5327 8.736 19.978 8.37019 20.276L7.71656 20.814H5.78156C5.45044 20.814 5.18194 21.0796 5.18194 21.4071C5.18194 21.7346 5.45044 22 5.78156 22H18.4779C18.8092 22 19.0776 21.7346 19.0776 21.4071C19.0776 21.0796 18.8092 20.814 18.4779 20.814H16.2834L15.6298 20.276C15.264 19.978 15.0536 19.5327 15.0576 19.0637V17.4933H21.9293C23.0649 17.4903 23.9852 16.5817 23.9906 15.4586V13.4922C23.9906 13.4592 24 13.4255 24 13.391C24 13.3561 23.9906 13.3225 23.9906 13.2895ZM2.07075 1.18599H21.9293C22.3995 1.18599 22.7916 1.59102 22.7916 2.05614V12.8087H1.20844V2.05614C1.20844 1.59102 1.6005 1.18599 2.07075 1.18599ZM14.472 20.814H9.52819C9.93206 20.3396 10.1415 19.7067 10.1415 19.0637V17.4933H13.8585V19.0637C13.8585 19.7067 14.0679 20.3396 14.472 20.814ZM21.9293 16.3073H2.07075C1.59694 16.3052 1.21294 15.9269 1.20844 15.4586V13.9946H22.7916V15.4586C22.7871 15.9269 22.4031 16.3052 21.9293 16.3073Z"
                                  fill="#4D4950"
                                />
                              </svg>
                            </div>
                            <div
                              @click="toggleView('mobile')"
                              class="toggleViewIcon d-flex justify-content-center align-items-center rounded-lg large"
                              :class="{ active: emailBrowserView === 'mobile' }"
                            >
                              <svg
                                width="16"
                                height="24"
                                viewBox="0 0 16 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M13.3125 0.9H13.3125L3.12284 0.9L3.12279 0.9C1.8834 0.90064 0.900411 2.04258 0.9 3.42288V3.42291L0.9 20.5771L0.9 20.5771C0.900411 21.9574 1.8834 23.0994 3.12279 23.1H3.12284H13.3125H13.3125C14.5519 23.0994 15.5347 21.9574 15.5353 20.5771V20.5771V3.42291V3.42287C15.5347 2.04259 14.5519 0.90064 13.3125 0.9ZM14.4862 20.5768C14.4843 21.3364 13.9477 21.9288 13.3121 21.9308H3.12316C2.48758 21.9288 1.95108 21.3365 1.94913 20.577V3.42304C1.95108 2.66361 2.4875 2.07131 3.12302 2.06916H13.3123C13.9478 2.07131 14.4843 2.66367 14.4862 3.42316V20.5768Z"
                                  fill="#4D4950"
                                  stroke="#B3AFB6"
                                  stroke-width="0.2"
                                />
                                <path
                                  d="M6.44675 19.6689H9.84329C10.145 19.6689 10.3679 19.3943 10.3679 19.0843C10.3679 18.7743 10.145 18.4997 9.84329 18.4997H6.44675C6.14492 18.4997 5.92219 18.7744 5.92219 19.0843C5.92219 19.3943 6.14492 19.6689 6.44675 19.6689Z"
                                  fill="#4D4950"
                                  stroke="#B3AFB6"
                                  stroke-width="0.2"
                                />
                              </svg>
                            </div>
                          </div>
                        </div>

                        <div
                          style="
                            padding: 20px;
                          "
                        >
                          <div
                            style="
                           
                              margin: 0px auto;
                              display: flex;
                              flex-direction: column;
                            "
                            :style="
                              emailBrowserView == 'desktop'
                                ? 'max-width: 1280px !important;'
                                : 'max-width:450px !important;'
                            "
                          >
                          <!-- max-height:70vh; overflow: hidden scroll; -->
                            <iframe :srcdoc="emailPreviewData.html" class="w-100 " style=" min-height: 70vh; border: none;" frameborder="0"></iframe>

                          </div>
                        </div>
                      </div>
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import LoaderComp from ".././../../../customize/component/LoaderComp.vue";
export default {
  components: { LoaderComp },
  props: {
    isEditMode: {
      type: Boolean,
      default: false,
    },
    isLoading: Boolean,
    emailTemplateDetail:{
      type:Object,
      default:()=>({
    "name": "",
    "selectedEmailTemplateId":null

})

    },
//      errors:{
//       type:Object,
//       default:()=>({
//     "name": "",

// })}
  },
  watch: {
    getSelectedTemplateId() {
      if (this.getSelectedTemplateId) {
        this.getEmailTemplatePreviewData();
      }
    },
  },
  data() {
    return {
      isReplayToEmail: false,
      isEmailTemplateLoading: false,
      emailTemplates: [],
      selectedEmailTemplateID: null,
      EmailTemplateName: "",
      isEmailPreviewLoading: false,
      emailPreviewData: null,
      emailBrowserView: "desktop",
    };
  },
  methods: {
    toggleView(view) {
      this.emailBrowserView = view;
    },
    open() {
      this.$refs.emailControl.open();
    },
    close() {
      this.$refs.emailControl.close();
    },

    selectTemplateID(id) {
      if (id) {
        this.emailTemplateDetail.selectedEmailTemplateId = id;
      }
    },
    async getEmailTemplatePreviewData() {
      try {
        this.isEmailPreviewLoading = true;
        let data = {
          template_id: this.emailTemplateDetail.selectedEmailTemplateId,
          image: 0,
          html: 1,
          json: 0,
        };
        const response = await axios.post("/email/templates/show", data);

        if (response.status == 200) {
          this.emailPreviewData = response?.data?.data;
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error Occured", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
      } finally {
        this.isEmailPreviewLoading = false;
      }
    },
    async getEmailTemplates() {
      try {
        this.isEmailTemplateLoading = true;
        let data = {
          per_page: 10,
          page: 1,
        };
        const response = await axios.get("/unlayer/email/templates", { params: data });

        if (response.status == 200) {
          this.emailTemplates = response?.data?.data;
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error Occured", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
      } finally {
        this.isEmailTemplateLoading = false;
      }
    },
  },
  computed: {
    getSelectedTemplateId(){
      return this.emailTemplateDetail.selectedEmailTemplateId
    }
    // isChooseTemplateValid() {
    //   return this.EmailTemplateName != "" && this.selectedEmailTemplateID;
    // },
  },
  mounted() {
    this.getEmailTemplates();
  },
};
</script>

<style scoped>
.quizell-form-control {
  height: 48px;
  border-radius: 10px;
  border: 1px solid #d2d8e0;
  border-radius: 8px;
  outline: none;
  box-shadow: none;
  transition: 0.3s ease all;
  background-color: transparent;
}
.question-control {
  border: 1px solid rgba(255, 162, 1, 1);
}

.quizell-form-control:focus-visible {
  border: none !important;
  outline: none;
  box-shadow: 0 0 0 0.1rem #4d1b7e;
}
label {
  color: rgba(115, 115, 141, 1);
  font-size: 12px;
}
.checkbox >>> .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #ffa201;
  background-color: #ffa201 !important;
}
.checkbox >>> .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem #ffa201;
}
.quizell-dropdown >>> a.btn {
  background: transparent !important;
  color: #4d1b7e !important;
  border: 1px solid #4d1b7e !important;
}
.quizell-dropdown >>> button {
  background: #4d1b7e !important;
  color: white;
  /* border: 1px solid #4d1b7e!important; */
}

.quizell-dropdown >>> ul li a:active {
  background: #4d1b7e !important;
  color: white;
  /* border: 1px solid #4d1b7e!important; */
}

.emailTemplatesContainer {
  max-height: 70vh;
  overflow: hidden scroll;
  scroll-behavior: smooth;
}

.singleEmailTemplateContainer {
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(10, 4, 17, 0.15);
  min-height: 300px;
}

.singleEmailTemplateContainer.selected {
  border: 3px solid #4d1b7e;
}

.templateName {
  color: #1f1f28;
  font-family: "Poppins";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 18px */
}

.htmlContainer {
  height: 100%;
  max-height: 80vh;
  overflow: hidden scroll;
  scroll-behavior: smooth;
}

.toggleViewIcon {
  cursor: pointer;
  background: transparent;
  height: 40px;
  width: 40px;
}
.toggleViewIcon.active {
  background: rgb(179 175 182 / 30%);
}
</style>
